<template>
  <el-card>
    <el-row>
      <el-date-picker
        v-model="dateRange"
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        end-placeholder="取消结束日期"
        range-separator="至"
        size="small"
        start-placeholder="取消开始日期"
        type="daterange"
      />
      <el-button class="vd_export" size="small" style="margin-bottom: 10px" type="info" @click="buttonRefresh()"> 刷新</el-button>
      <el-button class="vd_export" size="small" style="margin-bottom: 10px" type="primary" @click="getFequPushList()"> 查询</el-button>
      <el-button class="vd_export" size="small" style="margin-bottom: 10px" type="success" @click="haveKnown()"> 已知晓</el-button>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" v-loading="loading" :data="tableData" border class="vg_cursor" @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection" width="48"></el-table-column>
          <el-table-column align="center" label="序号" type="index" width="50px" />
          <el-table-column label="内容" min-width="150px" prop="reason" />
          <el-table-column align="center" label="操作" width="150px">
            <template v-slot="scope">
              <el-link
                class="vg_cursor"
                type="primary"
                @click="
                  jump(scope.row.perm_id === 185 ? '/inspecationprod_edit' : 'inspecationmtrb_edit', {
                    key: UrlEncode.encode(
                      JSON.stringify({
                        perm_id: scope.row.perm_id,
                        form_id: scope.row.form_id
                      })
                    )
                  })
                "
                >查看
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import bus from '@/components/common/bus';
import UrlEncode from '@assets/js/UrlEncode';
import { getArrayIds } from '@assets/js/arrayUtils';
import { pushAPI } from '@api/modules/push';

export default {
  name: 'push9010',
  computed: {
    UrlEncode() {
      return UrlEncode;
    }
  },
  data() {
    return {
      tableData: [],
      loading: true,
      dateRange: [],
      selectionsList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getFequPushList();
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //获取list
    getFequPushList() {
      this.loading = true;
      let [startTime, endTime] = this.dateRange || [];
      let start_time = startTime?.getTime();
      let end_time = endTime?.getTime();
      pushAPI.getPush9010({ stff_id: this.$cookies.get('userInfo').stff_id, startTime: start_time, endTime: end_time }).then(({ data }) => {
        this.tableData = data.list;
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
    //已知晓
    haveKnown() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      pushAPI.deletePush9010({ ids: getArrayIds(this.selectionsList, 'id').toString() }).then(() => {
        this.$message.success('操作成功!');
        this.initData();
        //刷新首页推送提醒数量
        bus.$emit('getPermInfo');
      });
    },
    //刷新
    buttonRefresh() {
      this.dateRange = [];
      this.initData();
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  max-width: 1500px !important; /* 根据需要设置 */
}

::v-deep .el-tooltip__popper {
  max-width: 1500px;
}
</style>
